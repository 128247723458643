import { TECTURE_AWARD_2024_SLUG } from "@/config/env";
import { useGetProject } from "@/features/project/api/getProject";
import AuthRedirectVote from "@/features/vote/components/AuthRedirectVote";
import VoteButtonOnProjectDetail from "@/features/vote/components/VoteButtonOnProjectDetail";
import { useSavedEntry } from "@/features/vote/hooks/useSavedEntry";
import useDevice from "@/hooks/useDevice";
import { useLineSession } from "@/hooks/useLineSession";
import clsx from "clsx";
import { FC } from "react";

interface Props {
  projectId: number;
}

const ProjectDetailVotingBar: FC<Props> = ({ projectId }) => {
  const { isSp } = useDevice();
  const { data: project, mutate } = useGetProject(projectId);
  const { isAuthenticated } = useLineSession();
  const { preVoteEntry } = useSavedEntry();

  return (
    <>
      <div
        className={clsx(
          "bg-black text-white p-16",
          "grid grid-cols-2 grid-rows-[auto_auto]",
          "gap-12",
          "laptop:gap-y-4",
        )}
      >
        <img
          className={clsx(
            "max-w-[313px] w-full block ",
            "col-span-full row-span-1 mx-auto",
            "laptop:col-start-1 laptop:col-end-2 laptop:mx-0",
          )}
          src={`/imgs/awards/tecture-award-2024/entry_project@2x.png`}
          alt="このプロジェクトはTECTURE AWARD 応募作品です"
        />

        <p
          className={clsx(
            "laptop:text-sm text-xs ",
            "col-start-1 col-end-2 row-start-2 row-end-3",
            "laptop:col-start-1 laptop:col-end-2",
          )}
        >
          {isSp ? (
            <>投票してこのプロジェクトを応援してみませんか？</>
          ) : (
            <>
              投票してこのプロジェクトを応援してみませんか？
              (複数の作品に投票できます）
            </>
          )}
        </p>

        <div
          className={clsx(
            "col-start-2 col-end-3 row-start-2 row-end-3 grid laptop:justify-items-end",
            "laptop:row-span-full",
          )}
        >
          {project && (
            <VoteButtonOnProjectDetail
              entry={project.award_entry}
              awardSlug={TECTURE_AWARD_2024_SLUG}
              onVoted={mutate}
              mainImage={project.main_image.urls.small}
              projectTitle={project.title}
            />
          )}
        </div>
      </div>

      {preVoteEntry && isAuthenticated && (
        <AuthRedirectVote
          entry={preVoteEntry}
          awardSlug={TECTURE_AWARD_2024_SLUG}
          onVoted={mutate}
        />
      )}
    </>
  );
};
export default ProjectDetailVotingBar;
