import { FC } from "react";
import clsx from "clsx";
import { AwardEntryEntity } from "@/web-client";
import IconVote from "@/assets/imgs/svg/icon_how_to_vote.svg";
import useDevice from "@/hooks/useDevice";
import useDisclosure from "@/hooks/useDisclosure";
import dynamic from "next/dynamic";
import { useVoteButton } from "@/features/vote/hooks/useVoteButton";
import useShareDialog from "@/features/vote/hooks/useShareDialog";
import { useHasVoted } from "@/features/vote/hooks/useHasVoted";

const DynamicDialogVoteOnLine = dynamic(
  () => import("@/features/vote/components/DialogVoteOnLine"),
);
interface Props {
  awardSlug: string;
  entry: AwardEntryEntity;
  onVoted: VoidFunction;
  projectTitle: string;
  mainImage: string;
}

const VoteButtonOnProjectDetail: FC<Props> = ({
  awardSlug,
  entry,
  onVoted,
}) => {
  const { isPc } = useDevice();

  // 投票説明ダイアログ開閉ステート
  const {
    isOpen: isOpenDialogVoteOnLine,
    closeHandler: closeDialogVoteOnLine,
    openHandler: openDialogVoteOnLine,
  } = useDisclosure();

  const { openDialog, ShareDialogComponent } = useShareDialog(entry);
  const hasVoted = useHasVoted(awardSlug, entry.id);
  const { clickHandler } = useVoteButton(
    awardSlug,
    entry,
    () => {
      onVoted();
      openDialog();
    },
    openDialogVoteOnLine,
  );

  if (hasVoted === undefined) return null;

  return (
    <>
      <div className="relative grid">
        <button
          className={clsx(
            "flex justify-center gap-x-8 items-center py-12 px-12 laptop:px-24",
            "rounded-xs laptop:text-base text-xs font-bold",
            "laptop:w-[294px]",
            hasVoted
              ? "bg-transparent text-[#B7AD84] border border-[#B7AD84] fill-[#B7AD84]"
              : "bg-[#B7AD84] text-primary  fill-black hover:bg-[#CBC2A6]",
          )}
          onClick={clickHandler}
          disabled={hasVoted}
        >
          <IconVote width={isPc ? 24 : 14} height={isPc ? 24 : 14} />
          <span>{hasVoted ? "投票済み" : "投票する"}</span>
        </button>

        <div
          className={clsx(
            "absolute top-[-3px] right-[-3px]",
            "text-black text-xs leading-none font-black",
            "bg-white pointer-events-none rounded-full p-4 px-6",
          )}
        >
          {entry.votes_count}
        </div>
      </div>

      {isOpenDialogVoteOnLine && (
        <DynamicDialogVoteOnLine
          isOpen={isOpenDialogVoteOnLine}
          close={closeDialogVoteOnLine}
          entry={entry}
        />
      )}

      {ShareDialogComponent}
    </>
  );
};
export default VoteButtonOnProjectDetail;
